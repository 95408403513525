import React, { useCallback, useMemo, useState } from 'react';

import { addOrderParamToPGTransferList } from '../../../../core/helpers/transferListHelper';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import { PGTransferListSection } from './pg-transfer-list-section';
import { PGTransferListArrows } from './pg-transfer-list-arrows';

import './style.css';

export const PGTransferList = ({
  onChange,
  defaultData,
  disabled = false
}) => {

  const [ leftContainerName, rightContainerName ] = Object.keys(defaultData);
  
  const [ data, setData ] = useState(addOrderParamToPGTransferList(defaultData));
  const [ leftSelectedItems, setLeftSelectedItems ] = useState({});
  const [ rightSelectedItems, setRightSelectedItems ] = useState({});

  useDidUpdateEffect(() => {
    if (disabled) {
      setData(addOrderParamToPGTransferList(defaultData));
      setLeftSelectedItems({});
      setRightSelectedItems({});
    }
  }, [disabled]);

  const leftSelectedCount = useMemo(() => {
    return Object.keys(leftSelectedItems).length;
  }, [leftSelectedItems]);

  const rightSelectedCount = useMemo(() => {
    return Object.keys(rightSelectedItems).length;
  }, [rightSelectedItems]);

  const handleSelect = useCallback((items, checked, setter) => {
    setter((prev) => {
      const result = { ...prev };
      items.forEach((item) => {
        if (checked) {
          result[item.value] = item;
        } else {
          delete result[item.value];
        }
      });
      return result;
    });
  }, []);

  const handleLeftSelect = useCallback((...args) => {
    handleSelect(...args, setLeftSelectedItems);
  }, [handleSelect, setLeftSelectedItems]);

  const handleRightSelect = useCallback((...args) => {
    handleSelect(...args, setRightSelectedItems);
  }, [handleSelect, setRightSelectedItems]);

  const transfer = useCallback((toRight) => {

    const fromContainerName = toRight ? leftContainerName : rightContainerName;
    const toContainerName = toRight ? rightContainerName : leftContainerName;
    let transferFromContainer = [...data[fromContainerName]];
    let transferToContainer = [...data[toContainerName]];
    const selectedItems = toRight ? leftSelectedItems : rightSelectedItems;
    const setSelectedItems = toRight ? setLeftSelectedItems : setRightSelectedItems;

    const transferItems = transferFromContainer.filter(({ value: groupValue, children }) => {
      return selectedItems[groupValue] || children?.some(({ value }) => selectedItems[value]);
    });

    const filteredTransferItems = transferItems.map((item) => {
      return {
        ...item,
        children: item.children?.filter(({ value }) => selectedItems[value])
      };
    });

    filteredTransferItems.forEach((transferItem) => {
      // adding
      let hasItemsInOtherSide = false;
      transferToContainer = transferToContainer.map((toItem) => {
        if (toItem.value === transferItem.value) {
          hasItemsInOtherSide = true;
          return {
            ...toItem,
            children: [...toItem.children, ...transferItem.children]
          }
        }
        return toItem;
      });
      if(!hasItemsInOtherSide) {
        transferToContainer.push(transferItem);
      }

      // removing
      transferFromContainer = transferFromContainer.map((fromItem) => {
        if (fromItem.value === transferItem.value) {
          const filteredFromItem = {
            ...fromItem,
            children: fromItem.children?.filter((fromItemChild) => !selectedItems[fromItemChild.value])
          };
          if (!filteredFromItem.children?.length) {
            return null;
          }
          return filteredFromItem;
        }
        return fromItem;
      }).filter(Boolean)
      
    });

    setSelectedItems({});

    const updatedData = {
      [toContainerName]: [...transferToContainer].sort((a, b) => a.order - b.order),
      [fromContainerName]: [...transferFromContainer].sort((a, b) => a.order - b.order)
    };

    setData(updatedData);
    onChange(updatedData);

  }, [data, leftContainerName, rightContainerName, leftSelectedItems, rightSelectedItems, onChange]);

  return (
    <div className="pg-transfer-list-wrapper">
      <PGTransferListSection
        title="Possible"
        disabled={disabled}
        onSelect={handleLeftSelect}
        onDrop={() => transfer(false)}
        data={data[leftContainerName]}
        selectedItems={leftSelectedItems}
        selectedCount={leftSelectedCount}
        containerName={leftContainerName}
        oppositeContainerName={rightContainerName}
      />
      <PGTransferListArrows
        leftArrowDisabled={!rightSelectedCount}
        rightArrowDisabled={!leftSelectedCount}
        onLeftArrowClick={() => transfer(false)}
        onRightArrowClick={() => transfer(true)}
      />
      <PGTransferListSection
        title="Included"
        disabled={disabled}
        onSelect={handleRightSelect}
        onDrop={() => transfer(true)}
        data={data[rightContainerName]}
        selectedItems={rightSelectedItems}
        selectedCount={rightSelectedCount}
        containerName={rightContainerName}
        oppositeContainerName={leftContainerName}
      />
    </div>
  );
};
