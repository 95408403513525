import { ApiVersion } from '../enums/ApiVersion';
import { API_CORE } from './urls';

/**
 * @name addUserUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const addUserUrl = (option) => `${API_CORE}/${option.version}/Users`;

/**
 * @name getUsersUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getUsersUrl = (option) => `${API_CORE}/${option.version}/Users`;

/**
 * @name editUserUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const editUserUrl = (option) => `${API_CORE}/${option.version}/Users`;

/**
 * @name editIfBlockUrl
 * @param {ApiOption} option
 * @param {number} id
 * @return {string}
 */
export const editIfBlockUrl = (id, option) => `${API_CORE}/${option.version}/Users/${id}/block`;

/**
 * @name getUsersInfoUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getUsersInfoUrl = (option) => `${API_CORE}/${option.version}/Users/info`;

/**
 * @name editUserInfo
 * @param {ApiOption} option
 * @return {string}
 */
export const editUserInfoUrl = (option) => `${API_CORE}/${option.version}/Users/info`;

/**
 * @name editPasswordUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const editPasswordUrl = (option) => `${API_CORE}/${option.version}/Users/changePassword`;

/**
 * @name sendResetPasswordEmailUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const sendResetPasswordEmailUrl = (option) => `${API_CORE}/${option.version}/Users/sendResetPasswordEmail`;

/**
 * @name resetPasswordUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const resetPasswordUrl = (option) => `${API_CORE}/${option.version}/Users/resetPassword`;

/**
 * @name confirmEmailUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const confirmEmailUrl = (id, option) => `${API_CORE}/${option.version}/Users/${id}/confirmEmail`;

/**
 * @name getUserDetailedUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const getUserDetailedUrl = (id, option) => `${API_CORE}/${option.version}/Users/${id}/detailed`;

/**
 * @name getcustomizationConfigUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getCustomizationConfigUrl = (option) => `${API_CORE}/${option.version}/Users/customizationConfig`;

/**
 * @name getcustomizationConfigUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const editCustomizationConfigUrl = (option) => `${API_CORE}/${option.version}/Users/customizationConfig`;

/**
 * @name getCurrencyUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getCurrencyUrl = (option) => `${API_CORE}/${option.version}/Currencies`;

/**
 * @name getCurrencyByCodeUrl
 * @param {string} code
 * @param {ApiOption} option
 * @returns {string}
 */
export const getCurrencyByCodeUrl = (code, option) => `${API_CORE}/${option.version}/Currencies/${code}`;

/**
 * @name getCurrencyChart
 * @param {ApiOption} option
 * @return {string}
 */
export const getCurrencyChart = (option) => `${API_CORE}/${option.version}/currencies/chart`;


/**
 * @name exportCurrenciesUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const exportCurrenciesUrl = (option) => `${API_CORE}/${option.version}/currencies/export`;

/**
 * @name exportCurrenciesChartUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const exportCurrenciesChartUrl = (option) => `${API_CORE}/${option.version}/currencies/chart/export`;

/**
 * @name getGamesUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getGamesUrl = (option) => `${API_CORE}/${option.version}/Games`;

/**
 * @name getAllGamesUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getAllGamesUrl = (option) => `${API_CORE}/${option.version}/Games/list`;

/**
 * @name getGamesPlatformsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const getGamesPlatformsUrl = (id, option) => `${API_CORE}/${option.version}/Games/${id}/withPlatforms`;

/**
 * @name getGameTypesByGameUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const getGameTypesByGameUrl = (id, option) => `${API_CORE}/${option.version}/Games/${id}/gameTypes`;

/**
 * @name getGameLinesUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getGameLinesUrl = (option) => `${API_CORE}/${option.version}/Games/categories`;

/**
 * @name getGamesInfoUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const getGamesInfoUrl = (id, option) => `${API_CORE}/${option.version}/games/${id}/info`;

/**
 * @name getGamesConfigsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const getGamesConfigsUrl = (id, option) => `${API_CORE}/${option.version}/games/${id}/configs`;

/**
 * @name getGamesPlatformFeatureUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getGamesPlatformFeatureUrl = (option) => `${API_CORE}/${option.version}/games/platformFeature`;

/**
 * @name getGamesFeaturePartnersUrl
 * @param {ApiOption} option
 * @param {number} id
 * @return {string}
 */
export const getGamesFeaturePartnersUrl = (id, option) => `${API_CORE}/${option.version}/games/${id}/feature/partners`;

/**
 * @name editGameInfoUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const editGameInfoUrl = (option) => `${API_CORE}/${option.version}/games/info`;

/**
 * @name editGamesPartnersUrl
 * @param {ApiOption} option
 * @param {number} id
 * @returns {string}
 */
export const editGamesPartnersUrl = (id,  option) => `${API_CORE}/${option.version}/games/${id}/partners`;

/**
 * @name editGamesConfigsUrl
 * @param {ApiOption} option
 * @param {number} id
 * @returns {string}
 */
export const editGamesConfigsUrl = (id, option) => `${API_CORE}/${option.version}/games/${id}/configs`;

/**
 * @name editGamesFeatureUrl
 * @param {ApiOption} option
 * @param {number} id
 * @returns {string}
 */
export const editGamesFeatureUrl = (id,  option) => `${API_CORE}/${option.version}/games/${id}/feature`;

/**
 * @name getNotificationUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getNotificationUrl = (option) => `${API_CORE}/${option.version}/notifications`;

/**
 * @name getNotificationUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getReadNotificationUrl = (id, option) => `${API_CORE}/${option.version}/notifications/${id}`;

/**
 * @name readAllNotificationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const readAllNotificationsUrl = (option) => `${API_CORE}/${option.version}/notifications/readAll`;

/**
 * @name getPartnersUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getPartnersUrl = (option) => `${API_CORE}/${option.version}/partners`;

/**
 * @name editPartnerGamesUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const editPartnerGamesUrl = (option) => `${API_CORE}/${option.version}/partners/games`;

/**
 * @name getAddInternalPartnerUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getAddInternalPartnerUrl = (option) => `${API_CORE}/${option.version}/partners/internal`;

/**
 * @name getPartnerInfoByIdUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getPartnerInfoByIdUrl = (id, option) => `${API_CORE}/${option.version}/partners/${id}/info`;

/**
 * @name partnersIdGameTypeLimitUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const partnersIdGameTypeLimitUrl = (id, option) => `${API_CORE}/${option.version}/partners/${id}/gameTypeLimit`;

/**
 * @name getPartnersGameConfigUrl
 * @param {number} id
 * @param {ApiOption} option
 * @param {number} gameId
 * @returns {string}
 */
export const getPartnersGameConfigUrl = (id, gameId, option) => `${API_CORE}/${option.version}/partners/${id}/gameConfig/${gameId}`;

/**
 * @name editPartnersGameConfigUrl
 * @param {number} id
 * @param {ApiOption} option
 */
export const editPartnersGameConfigUrl = (id, option) => `${API_CORE}/${option.version}/partners/${id}/gameConfig`;

/**
 * @name getPartnersGameTypeUrl
 * @param {number} partnerId
 * @param {number} gameTypeId
 * @param {ApiOption} option
 * @returns {string}
 */
export const getPartnersGameTypeUrl = (partnerId, gameTypeId, option) => `${API_CORE}/${option.version}/partners/${partnerId}/gameType/${gameTypeId}`;

/**
 * @name partnersGameTypeLimitUrl
 * @param {number} gameTypeId
 * @param {ApiOption} option
 * @return {string}
 */
export const partnersGameTypeLimitUrl = (gameTypeId, option) => `${API_CORE}/${option.version}/partners/gameTypeId/${gameTypeId}/limit`;

/**
 * @name getAddExternalPartnerUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getAddExternalPartnerUrl = (option) => `${API_CORE}/${option.version}/partners/external`;

/**
 * @name exportPartnersGameTypeLimitsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const exportPartnersGameTypeLimitsUrl = (id, option) => `${API_CORE}/${option.version}/partners/${id}/gameLimits/export`;

/**
 * @name getPartnersGameTypeLimitsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getPartnersGameTypeLimitsCurrenciesUrl = (option) => `${API_CORE}/${option.version}/partners/gameTypeLimits`;

/**
 * @name exportPartnerGameTypeLimitsUrl
 * @param {ApiOption} option
 * @param {number} id
 * @return {string}
 */
export const exportPartnerGameTypeLimitsCurrenciesUrl = (id, option) => `${API_CORE}/${option.version}/partners/${id}/gameTypeLimits/export`;

/**
 * @name importPartnersGameTypeLimitsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {`string`}
 */
export const importPartnersGameTypeLimitsUrl = (id, option) => `${API_CORE}/${option.version}/partners/${id}/gameTypeLimits/import`;

/**
 * @name refreshTokenUrl
 * @param {ApiOption} option
 * @return {string}
 */
const refreshTokenUrl = (option) => `${API_CORE}/auth/refresh`;
export const refreshTokenUrlConstant = refreshTokenUrl({ version: ApiVersion.V1 });

/**
 * @name signOutUrl
 * @return {string}
 */
export const signOutUrl = (timestamp) => `${API_CORE}/auth/logout?timestamp=${timestamp}`;

/**
 * @name getGameTypesUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getGameTypesUrl = (option) => `${API_CORE}/${option.version}/gameTypes`;

/**
 * @name getEditGameTypeUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getEditGameTypeUrl = (id, option) => `${API_CORE}/${option.version}/gameTypes/${id}`;

/**
 * @name getGameTypeDetailedUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getGameTypeDetailedUrl = (id, option) => `${API_CORE}/${option.version}/gameTypes/${id}/detailed`;

/**
 * @name gameTypesLimitsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const gameTypesLimitsUrl = (id, option) => `${API_CORE}/${option.version}/gameTypes/${id}/limits`;

/**
 * @name exportGameTypesLimitsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const exportGameTypesLimitsUrl = (id, option) => `${API_CORE}/${option.version}/gameTypes/${id}/limits/export`;

/**
 * @name getMaintenancesUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getMaintenancesUrl = (option) => `${API_CORE}/${option.version}/maintenances`;

/**
 * @name editMaintenancesByIdUrl
 * @param {number} maintenanceId
 * @param {ApiOption} option
 * @returns {string}
 */
export const editMaintenancesByIdUrl = (maintenanceId, option) => `${API_CORE}/${option.version}/Maintenances/${maintenanceId}`;

/**
 * @name addMaintenanceUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const addMaintenanceUrl = (option) => `${API_CORE}/${option.version}/maintenances`;

/**
 * @name getStaticContentsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getStaticContentsUrl = (option) => `${API_CORE}/${option.version}/staticContents`;

/**
 * @name addStaticContentsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const addStaticContentsUrl = (option) => `${API_CORE}/${option.version}/staticContents`;

/**
 * @name editStaticContentsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const editStaticContentsUrl = (option) => `${API_CORE}/${option.version}/staticContents`;

/**
 * @name deleteStaticContentsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const deleteStaticContentsUrl = (id, option) => `${API_CORE}/${option.version}/staticContents/${id}`;

/**
 * @name getRoundReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getRoundReportsUrl = (option) => `${API_CORE}/${option.version}/reports/bog/rounds`;

/**
 * @name getBogBetReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getBogBetReportsUrl = (option) => `${API_CORE}/${option.version}/reports/bog/bets`;

/**
 * @name getTableGamesRoundReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTableGamesRoundReportsUrl = (option) => `${API_CORE}/${option.version}/reports/table/rounds`;

/**
 * @name getTableGamesBetReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTableGamesBetReportsUrl = (option) => `${API_CORE}/${option.version}/reports/table/bets`;

/**
 * @name getLotteryRoundReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getLotteryRoundReportsUrl = (option) => `${API_CORE}/${option.version}/reports/lotto/rounds`;

/**
 * @name getLotteryBetReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getLotteryBetReportsUrl = (option) => `${API_CORE}/${option.version}/reports/lotto/bets`;

/**
 * @name getBetshopRoundReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getBetshopRoundReportsUrl = (option) => `${API_CORE}/${option.version}/reports/betshop/rounds`;

/**
 * @name getBetshopBetReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getBetshopBetReportsUrl = (option) => `${API_CORE}/${option.version}/reports/betshop/bets`;

/**
 * @name getBetshopBetDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getBetshopBetDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/betshop/betDetails`;


/**
 * @name getBetshopMarketReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getBetshopMarketReportsUrl = (option) => `${API_CORE}/${option.version}/reports/betshop/markets`;

/**
 * @name getSlotBetReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getSlotBetReportsUrl = (option) => `${API_CORE}/${option.version}/reports/slots/bets`;

/**
 * @name getSlotBetDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getSlotBetDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/slots/betDetails`;

/**
 * @name getTronixBetReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTronixBetReportsUrl = (option) => `${API_CORE}/${option.version}/reports/tronixzone/bets`;

/**
 * @name getTronixBetDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTronixBetDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/tronixzone/betDetails`;

/**
 * @name getVirtualBetDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getVirtualBetDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/virtual/betDetails`;

/**
 * @name getLotteryBetDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getLotteryBetDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/lotto/betDetails`;

/**
 * @name getBogBetDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getBogBetDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/bog/betDetails`;

/**
 * @name getTableGamesBetDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTableGamesBetDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/table/betDetails`;

/**
 * @name getArcadeGamesBetDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getArcadeGamesBetDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/arcade/betDetails`;

/**
 * @name getVirtualBetReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getVirtualBetReportsUrl = (option) => `${API_CORE}/${option.version}/reports/virtual/bets`;

/**
 * @name getVirtualRoundReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getVirtualRoundReportsUrl = (option) => `${API_CORE}/${option.version}/reports/virtual/matches`;

/**
 * @name getVirtualMarketReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getVirtualMarketReportsUrl = (option) => `${API_CORE}/${option.version}/reports/virtual/markets`;

/**
 * @name getTableGamesRoundDetailsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTableGamesRoundDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/table/roundDetails`;

/**
 * @name getBogGamesRoundDetailsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getBogGamesRoundDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/bog/roundDetails`;

/**
 * @name getArcadeBetReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getArcadeBetReportsUrl = (option) => `${API_CORE}/${option.version}/reports/arcade/bets`;

export const getArcadeRoundReportsUrl = (option) => `${API_CORE}/${option.version}/reports/arcade/rounds`;

/**
 * @name getPermissionUserUrl
 * @param {number} userId
 * @param {ApiOption} option
 * @returns {string}
 */
export const getPermissionUserUrl = (userId, option) => `${API_CORE}/${option.version}/permissions/${userId}`;

/**
 * @name getAllPermissionsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getAllPermissionsUrl = (option) => `${API_CORE}/${option.version}/permissions`;

/**
 * @name getTransactionsReportsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTransactionsReportsUrl = (option) => `${API_CORE}/${option.version}/reports/transactions`;

/**
 * @name getTransactionsReportsResultsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportsResultsUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/results`;

/**
 * @name getTransactionsReportsDashboardUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportsDashboardUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/dashboard`;

/**
 * @name getTransactionsReportsGameLinesUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportsGameLinesUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/dashboard/gameLine`;

/**
 * @name getTransactionsReportsGamesUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportsGamesUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/games`;

/**
 * @name getTransactionsReportsFinancialsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportsFinancialsUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/financial`;

/**
 * @name exportFinancialReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const exportFinancialReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/financial/export`;

/**
 * @name getFinancialInvoiceReportInfoUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getFinancialInvoiceReportInfoUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/financial/sendMail/info`;

/**
 * @name generateFinancialInvoiceReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const generateFinancialInvoiceReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/financial/sendMail`;

/**
 * @name getTransactionsReportsChartUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportsChartUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/chart`;

/**
 * @name getTransactionsReportsTopBetsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportsTopBetsUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/topBets`;

/**
 * @name getTransactionsReportsPartnerActivityUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportsPartnerActivityUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/partnerActivity`;

/**
 * @name getTransactionsUsersCount
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsReportUsersCountUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/usersCount`;

/**
 * @name getPlayersGameLineTransactionsReport
 * @param {ApiOption} option
 * @return {string}
 */
export const getPlayersGameLineTransactionsReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/players/gameLine`;

/**
 * @name getTransactionsCurrencyReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getTransactionsCurrencyReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/currency`;

export const getTransactionCurrencyReportsByPartnersUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/currency/partners`;

/**
 * @name getGameComparisonReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getGameComparisonReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/gameComparison`;

/**
 * @name exporGameComparisonReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const exporGameComparisonReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/gameComparison/export`;

/**
 * @name getPartnerComparisonReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getPartnerComparisonReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/partnerComparison`;

/**
 * @name exporPartnerComparisonReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const exporPartnerComparisonReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/partnerComparison/export`;

/**
 * @name getPartnerComparisonReportByGameUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getPartnerComparisonReportByGameUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/partnerComparisonByGame`;

/**
 * @name getLanguagesUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getLanguagesUrl = (option) => `${API_CORE}/${option.version}/languages`;

/**
 * @name addLanguagesUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const addLanguagesUrl = (option) => `${API_CORE}/${option.version}/languages`;

/**
 * @name editLanguagesUrl
 * @param {ApiOption} option
 * @param {string} id
 * @returns {string}
 */
export const editLanguagesUrl = (id, option) => `${API_CORE}/${option.version}/languages/${id}`;

/**
 * @name deleteLanguagesUrl
 * @param {ApiOption} option
 * @param {string} id
 * @returns {string}
 */
export const deleteLanguagesUrl = (id, option) => `${API_CORE}/${option.version}/languages/${id}`;

/**
 * @name getUserTournamentsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getUserTournamentsUrl = (option) => `${API_CORE}/${option.version}/tournaments/UserTournaments`;

/**
 * @name getUserTournamentsReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getUserTournamentsReportUrl = (option) => `${API_CORE}/${option.version}/tournaments/UserTournaments/report`;

/**
 * @name getUserTournamentsRangeReportUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getUserTournamentsRangeReportUrl = (option) => `${API_CORE}/${option.version}/tournaments/UserTournaments/rangeReport`;

/**
 * @name getUserTournamentUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getUserTournamentUrl = (option) => `${API_CORE}/${option.version}/tournaments/UserTournaments/tournament`;

/**
 * @name doBetUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const doBetUrl = (option) => `${API_CORE}/${option.version}/freebetService/Bets/doBet`;

/**
 * @name doRollbackUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const doRollbackUrl = (option) => `${API_CORE}/${option.version}/freebetService/Bets/doRollback`;

/**
 * @name doWinUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const doWinUrl = (option) => `${API_CORE}/${option.version}/freebetService/Bets/doWin`;

/**
 * @name batchWinUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const batchWinUrl = (option) => `${API_CORE}/${option.version}/freebetService/Bets/batchWin`;

/**
 * @name getUserFreeBetsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getUserFreeBetsUrl = (option) => `${API_CORE}/${option.version}/userFreeBets/report`;

/**
 * @name exportUserFreeBetsReportUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const exportUserFreeBetsReportUrl = (option) => `${API_CORE}/${option.version}/userFreeBets/report/export`;

/**
 * @name getUserFreeBetStatusHistoryUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getUserFreeBetStatusHistoryUrl = (id, option) => `${API_CORE}/${option.version}/userFreeBets/${id}/statusHistory`;

/**
 * @name getUserFreeBetReportDetailsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getUserFreeBetReportDetailsUrl = (userFreeBetId, option) => `${API_CORE}/${option.version}/userFreeBets/${userFreeBetId}/reportByGame`;

/**
 * @name editUserFreeBetsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const addUserFreeBetsUrl = (option) => `${API_CORE}/${option.version}/userFreeBets`;

/**
 * @name getFreeBetTemplatesUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getFreeBetTemplatesUrl = (option) => `${API_CORE}/${option.version}/freeBetTemplates`;

/**
 * @name addFreeBetTemplateUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const addFreeBetTemplateUrl = (option) => `${API_CORE}/${option.version}/freeBetTemplates`;

/**
 * @name editFreeBetTemplateUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const editFreeBetTemplateUrl = (option) => `${API_CORE}/${option.version}/freeBetTemplates`;

/**
 * @name getFreeBetTemplateDetailsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getFreeBetTemplateDetailsUrl = (id, option) => `${API_CORE}/${option.version}/freeBetTemplates/${id}`;

/**
 * @name cancelFreeBetTemplateUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const cancelFreeBetTemplateUrl = (id, option) => `${API_CORE}/${option.version}/freeBetTemplates/${id}/cancel`;

/**
 * @name getFreeBetTemplateReportUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getFreeBetTemplateReportUrl = (id, option) => `${API_CORE}/${option.version}/freeBetTemplates/${id}/report`;

/**
 * @name getAddTournamentsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getAddTournamentsUrl = (option) => `${API_CORE}/${option.version}/tournaments`;

/**
 * @name getTournamentsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTournamentsUrl = (option) => `${API_CORE}/${option.version}/tournaments`;

/**
 * @name getEditTournamentsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getEditTournamentsUrl = (option) => `${API_CORE}/${option.version}/tournaments`;

/**
 * @name getTournamentByIdUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTournamentByIdUrl = (option) => `${API_CORE}/${option.version}/tournaments/details`;

/**
 * @name getTournamentsRangeIdUrl
 * @param {number} rangeId
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTournamentsRangeIdUrl = (rangeId, option) => `${API_CORE}/${option.version}/tournaments/Tournaments/ranges/${rangeId}`;

/**
 * @name getCancelTournamentsIdUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getCancelTournamentsIdUrl = (option) => `${API_CORE}/${option.version}/Tournaments/cancel`;

/**
 * @name  getEditTournamentsPartnerStatusUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getEditTournamentsPartnerStatusUrl = (option) => `${API_CORE}/${option.version}/tournaments/Tournaments/partnerStatus`;

/**
 * @name getTournamentsPlayersUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTournamentsPlayersUrl = (option) => `${API_CORE}/${option.version}/Tournaments/players`;

/**
 * @name getTournamentsPartnerUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTournamentsPartnerUrl = (option) => `${API_CORE}/${option.version}/tournaments/partners`;

/**
 * @name addPlatformsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const addPlatformsUrl = (option) => `${API_CORE}/${option.version}/platforms`;

/**
 * @name editPlatformsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const editPlatformsUrl = (option) => `${API_CORE}/${option.version}/platforms`;

/**
 * @name getPlatformsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getPlatformsUrl = (option) => `${API_CORE}/${option.version}/Platforms`;

/**
 * @name getPlatformPartnersUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getPlatformPartnersUrl = (option) => `${API_CORE}/${option.version}/Platforms/partners`;

/**
 * @name getPlatformGamesUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getPlatformGamesUrl = (id, option) => `${API_CORE}/${option.version}/platforms/${id}/games`;

/**
 * @name getPlatformInfoUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getPlatformInfoUrl = (id, option) => `${API_CORE}/${option.version}/platforms/${id}/info`;

/**
 * @name editPlatformGamesUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const editPlatformGamesUrl = (option) => `${API_CORE}/${option.version}/platforms/games`;

/**
 * @name addPlatformGameLimitsUrl
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const addPlatformGameLimitsUrl = (id, option) => `${API_CORE}/${option.version}/platforms/${id}/gameLimits`;

/**
 * @name editPlatformPartnersRealModeSwitcherUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const editPlatformPartnersRealModeSwitcherUrl = (id, option) => `${API_CORE}/${option.version}/platforms/${id}/realModeSwitcher`;

export const editHasTournamentPlatformPartnerUrl = (id, option) => `${API_CORE}/${option.version}/platforms/${id}/hasTournament`;

/**
 * @name getTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations`;

/**
 * @name getTranslationListUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTranslationListUrl = (option) => `${API_CORE}/${option.version}/translations/backoffice`;

/**
 * @name editTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const editTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations`;

/**
 * @name editRichTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const editRichTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations/rich`;

/**
 * @name deleteTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const deleteTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations`;

/**
 * @name deleteAllTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const deleteAllTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations/delete`;

/**
 * @name addTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const addTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations`;

/**
 * @name addRichTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const addRichTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations/rich`;

/**
 * @name getTranslationsDetailedUrl
 * @param {string} alias
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTranslationsDetailedUrl = (alias, option) => `${API_CORE}/${option.version}/translations/${alias}/detailed`;

/**
 * @name getTranslationLabelByAliasUrl
 * @param {string} alias
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTranslationLabelByAliasUrl = (alias, option) => `${API_CORE}/${option.version}/translations/${alias}`;

/**
 * @name importTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const importTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations/import`;

/**
 * @name exportTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const exportTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations/export`;

/**
 * @name exportSelectedTranslationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const exportSelectedTranslationsUrl = (option) => `${API_CORE}/${option.version}/translations/exportAlias`;

/**
 * @name getTranslationGroupsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getTranslationGroupsUrl = (option) => `${API_CORE}/${option.version}/translationGroups`;

/**
 * @name addTranslationGroupUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const addTranslationGroupUrl = (option) => `${API_CORE}/${option.version}/translationGroups`;

/**
 * @name getApplicationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getApplicationsUrl = (option) => `${API_CORE}/${option.version}/applications`;

/**
 * @name addApplicationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const addApplicationsUrl = (option) => `${API_CORE}/${option.version}/applications`;

/**
 * @name editApplicationsUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const editApplicationsUrl = (option) => `${API_CORE}/${option.version}/applications`;

/**
 * @name addApplicationsUserUrl
 * @param option
 * @returns {string}
 */
export const addApplicationsUserUrl = (option) => `${API_CORE}/${option.version}/applications/user`;

/**
 * @name getApplicationsUserUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const getApplicationsUserUrl = (option) => `${API_CORE}/${option.version}/applications/user`;

/**
 * @name editTranslationGroupUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const editTranslationGroupUrl = (id, option) => `${API_CORE}/${option.version}/translationGroups/${id}`;

/**
 * @name deleteTranslationGroupUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const deleteTranslationGroupUrl = (id, option) => `${API_CORE}/${option.version}/translationGroups/${id}`;

/**
 * @name addTranslationOnTranslationGroupUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const addTranslationsOnTranslationGroupUrl = (option) => `${API_CORE}/${option.version}/translationGroups/translation`;

/**
 * @name addTranslationOnTranslationGroupUrl
 * @param {ApiOption} option
 * @returns {string}
 */
export const deleteTranslationsFromTranslationGroupUrl = (option) => `${API_CORE}/${option.version}/translationGroups/translation`;
/**
 * @name getApplicationLanguagesdUrl
 * @param {string} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getApplicationLanguagesdUrl = (id, option) => `${API_CORE}/${option.version}/applications/${id}/languages`;

/**
 * @name deleteApplicationUrl
 * @param {number} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const deleteApplicationUrl = (id, option) => `${API_CORE}/${option.version}/applications/${id}`;

/**
 * @name getLanguageUrl
 * @param {string} id
 * @param {ApiOption} option
 * @returns {string}
 */
export const getLanguageUrl = (id, option) => `${API_CORE}/${option.version}/languages/${id}`;

/**
 * @name getJackpotsUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const getJackpotsUrl = (option) => `${API_CORE}/${option.version}/jackpots/reports`;

/**
 * @name getPlayerJackpotReports
 * @param {ApiOption} option
 * @return {string}
 */
export const getPlayerJackpotReportsUrl = (option) => `${API_CORE}/${option.version}/jackpots/reports/player`;

/**
 * @name addJackpotUrl
 * @param {ApiOption} option
 * @return {string}
 */
export const addJackpotUrl = (option) => `${API_CORE}/${option.version}/jackpots`;

/**
 * @name getJackpot
 * @param {number} id
 * @param {ApiOption} option
 * @return {string}
 */
export const getJackpotUrl = (id, option) => `${API_CORE}/${option.version}/jackpots/${id}`;

/**
 * @name getJackpotDetailedUrl
 * @param {number} id
 * @param { ApiOption } option
 * @return {string}
 */
export const getJackpotDetailedUrl = (id, option) => `${API_CORE}/${option.version}/jackpots/${id}/detailed`;

/**
 * @name getPromoTemplatesUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const getPromoTemplatesUrl = (option) => `${API_CORE}/${option.version}/promoTemplates`;

/**
 * @name addPromoTemplateUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const addPromoTemplateUrl = (option) => `${API_CORE}/${option.version}/promoTemplates`;

/**
 * @name editPromoTemplateUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const editPromoTemplateUrl = (option) => `${API_CORE}/${option.version}/promoTemplates`;

/**
 * @name getPromoTemplateByIdUrl
 * @param { number } id
 * @param { ApiOption } option
 * @return {string}
 */
export const getPromoTemplateByIdUrl = (id, option) => `${API_CORE}/${option.version}/promoTemplates/${id}`;

/**
 * @name cancelPromoTemplateUrl
 * @param { number } id
 * @param { ApiOption } option
 * @return {string}
 */
export const cancelPromoTemplateUrl = (id, option) => `${API_CORE}/${option.version}/promoTemplates/${id}/cancel`;

/**
 * @name getPromoPlayersReportUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const getPromoPlayersReportUrl = (option) => `${API_CORE}/${option.version}/promoTemplates/players/report`;

/**
 * @name pgTournamentsUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const pgTournamentsUrl = (option) => `${API_CORE}/${option.version}/PGTournaments`;

/**
 * @name pgTournamentDetailsUrl
 * @param { number } id
 * @param { ApiOption } option
 * @return {string}
 */
export const pgTournamentDetailsUrl = (id, option) => `${API_CORE}/${option.version}/PGTournaments/${id}/details`;

/**
 * @name cancelPGTournamentUrl
 * @param { number } id
 * @param { ApiOption } option
 * @return {string}
 */
export const cancelPGTournamentUrl = (id, option) => `${API_CORE}/${option.version}/PGTournaments/${id}/cancel`;

/**
 * @name getPGTournamentReportUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const getPGTournamentReportUrl = (option) => `${API_CORE}/${option.version}/PGTournaments/report`;

/**
 * @name getPGTournamentReportByGameUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const getPGTournamentReportByGameUrl = (option) => `${API_CORE}/${option.version}/PGTournaments/game/report`;

/**
 * @name getPGTournamentReportByPlayerUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const getPGTournamentReportByPlayerUrl = (option) => `${API_CORE}/${option.version}/PGTournaments/player/report`;

export const getPlayersReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/players`;

export const getPlayersByGameReportUrl = (option) => `${API_CORE}/${option.version}/reports/transactions/playersByGame`;


/**
 * @name pgCashbackUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const pgCashbackUrl = (option) => `${API_CORE}/${option.version}/PGCashbacks`;

/**
 * @name pgCashbackUrl
 * @param { ApiOption } option
 * @param { number } id
 * @return {string}
 */
export const getPGCashbackUrl = (id, option) => `${API_CORE}/${option.version}/PGCashbacks/${id}/details`;

/**
 * @name cancelPGCashbackUrl
 * @param { ApiOption } option
 * @param { number } id
 * @return {string}
 */
export const cancelPGCashbackUrl = (id ,option) => `${API_CORE}/${option.version}/PGCashbacks/${id}/cancel`;

/**
 * @name getPGCashbackReportUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const getPGCashbackReportUrl = (option) => `${API_CORE}/${option.version}/PGCashbacks/report/cashbacks`;

/**
 * @name getPGCashbackPlayerReportUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const getPGCashbackPlayerReportUrl = (option) => `${API_CORE}/${option.version}/PGCashbacks/report/players`;

/**
 * @name getPGCashbackPlayerDetailsReportUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const getPGCashbackPlayerDetailsReportUrl = (id, option) => `${API_CORE}/${option.version}/PGCashbacks/${id}/details`;

/**
 * @name pgLobbyUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const pgLobbyUrl = (option) => `${API_CORE}/${option.version}/lobby`;

/**
 * @name pgLobbyDetailsUrl
 * @param { ApiOption } option
 * @param { number } id
 * @return {string}
 */
export const pgLobbyDetailsUrl = (id,option) => `${API_CORE}/${option.version}/lobby/${id}/details`;

/**
 * @name boostedJackpotUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const boostedJackpotUrl = (option) => `${API_CORE}/${option.version}/BoostedJackpot`;

/**
 * @name playerBoostedJackpotReportsUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const playerBoostedJackpotReportsUrl = (option) => `${API_CORE}/${option.version}/BoostedJackpot/players`;

/**
 * @name exportPlayerBoostedJackpotReportsUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const exportPlayerBoostedJackpotReportUrl = (option) => `${API_CORE}/${option.version}/BoostedJackpot/export/players`;

/**
 * @name boostedJackpotDetailsUrl
 * @param { number } id
 * @param { ApiOption } option
 * @return {string}
 */
export const boostedJackpotDetailsUrl = (id, option) => `${API_CORE}/${option.version}/BoostedJackpot/${id}/details`;

/**
 * @name cancelBoostedJackpotUrl
 * @param { number } id
 * @param { ApiOption } option
 * @return {string}
 */
export const cancelBoostedJackpotUrl = (id, option) => `${API_CORE}/${option.version}/BoostedJackpot/${id}/cancel`;

/**
 * @name popupUrl
 * @param { ApiOption } option
 * @return {string}
 */
export const popupsUrl = (option) => `${API_CORE}/${option.version}/popups`;

/**
 * @name popupUrl
 * @param { number } id
 * @param { ApiOption } option
 * @return {string}
 */
export const cacnelPopupUrl = (id, option) => `${API_CORE}/${option.version}/popups/${id}/cancel`;

/**
 * @name popupUrl
 * @param { number } id
 * @param { ApiOption } option
 * @return {string}
 */
export const popupDetailsUrl = (id, option) => `${API_CORE}/${option.version}/popups/${id}/details`;

// START: Predictor APIs

export const predictorRoundsUrl = (option) => `${API_CORE}/${option.version}/rounds`;
export const predictorRoundsExportUrl = (option) => `${API_CORE}/${option.version}/rounds/export`;
export const predictorRoundDetailsUrl = (id, option) => `${API_CORE}/${option.version}/rounds/${id}`;
export const predictorRoundsByGameTypeUrl = (option) => `${API_CORE}/${option.version}/rounds/gameType`;
export const predictorCancelRoundUrl = (id, option) => `${API_CORE}/${option.version}/rounds/${id}/cancel`;
export const predictorChangeRoundVisibilityUrl = (option) => `${API_CORE}/${option.version}/rounds/visibility`;

export const predictorMatchesUrl = (option) => `${API_CORE}/${option.version}/matches`;
export const predictorMatchesExportUrl = (option) => `${API_CORE}/${option.version}/matches/export`;
export const predictorRecalculateMatchUrl = (option) => `${API_CORE}/${option.version}/matches/recalculate`;
export const predictorChangeMatchVisibilityUrl = (option) => `${API_CORE}/${option.version}/matches/visibility`;

export const predictorTeamsUrl = (option) => `${API_CORE}/${option.version}/teams`;
export const predictorTeamsExportUrl = (option) => `${API_CORE}/${option.version}/teams/export`;

export const predictorRegionsUrl = (option) => `${API_CORE}/${option.version}/regions`;

export const predictorSportsUrl = (option) => `${API_CORE}/${option.version}/sports`;

export const predictorPredictionsUrl = (option) => `${API_CORE}/${option.version}/predictions`;

export const predictorLeaderboardUrl = (option) => `${API_CORE}/${option.version}/reports/leaderboard`;
export const predictorLeaderboardExportUrl = (option) => `${API_CORE}/${option.version}/reports/export/leaderboard`;
export const predictorLeaderboardDetailsUrl = (option) => `${API_CORE}/${option.version}/reports/leaderboardDetails`;

export const predictorConfigurationUrl = (option) => `${API_CORE}/${option.version}/predictorPartnerConfiguration`;

export const predictorCompetitionsUrl = (option) => `${API_CORE}/${option.version}/competitions`;
export const getPredictorCompetitionsExportUrl = (option) => `${API_CORE}/${option.version}/competitions/export`;
export const getPredictorCompetitionsBySportUrl = (option) => `${API_CORE}/${option.version}/competitions/sports`;

// END: Predictor APIs